export default [
  {
    header: "QUICK ACCESS",
  },
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "LayersIcon",
  },
  {
    title: "Consultants",
    route: "consultants",
    icon: "UsersIcon",
  },
  {
    title: "Leads",
    route: "leads",
    icon: "ListIcon",
  },
  {
    title: "Payments",
    route: "payments",
    icon: "DollarSignIcon",
  },
  {
    header: "SYSTEM SETTINGS",
  },
  {
    title: "Admins",
    route: "admins",
    icon: "ShieldIcon",
  },
  {
    title: "Designations",
    route: "designations",
    icon: "ListIcon",
  },
  {
    title: "Services",
    route: "services",
    icon: "ListIcon",
  },
  {
    title: "Sectors",
    route: "sectors",
    icon: "ListIcon",
  },
];
