<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <!-- <h4 class="content-header-title mb-0">Smart Links</h4> -->
    </div>

    <b-navbar-nav class="nav user-details-nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">{{ userDetails }}</p>
            <p class="user-email mb-0">{{ userEmail }}</p>
          </div>
          <b-avatar size="40" variant="primary" :src="getProfilePic" />
          <feather-icon
            class="more-icon"
            icon="MoreVerticalIcon"
          ></feather-icon>
        </template>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item @click="logout" link-class="d-flex align-items-center">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import { getAuth, signOut } from "firebase/auth";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    userDetails: function() {
      const auth = getAuth();
      let user = auth.currentUser;
      if (user) {
        let userName = user.displayName;
        return userName;
      }

      return null;
    },
    userEmail: function() {
      const auth = getAuth();
      let user = auth.currentUser;

      if (user) {
        let userEmail = user.email;
        return userEmail;
      }

      return null;
    },
    getProfilePic: function() {
      const auth = getAuth();
      let user = auth.currentUser;
      if (user) {
        let userPhoto = user.photoURL;
        return userPhoto;
      }

      return null;
    },
  },
  methods: {
    logout() {
      let auth = getAuth();
      signOut(auth)
        .then(() => {
          this.$router.push("/login");
        })
        .catch((error) => {
          console.log("ERROR SIGNING OUT!");
        });
    },
  },
};
</script>
